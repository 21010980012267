import { getUserData } from '@/lib/api/favorite';
import { getMyToko } from '@/lib/api/myToko';
import { RootState } from '@/store';
import { saveTokoData } from '@/store/toko-data/action';
import { saveUserData } from '@/store/user-data/action';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useDispatch, useSelector } from 'react-redux';

const useLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { mutate: mutationMyToko } = useMutation(getMyToko);
  const {
    isLoading: isLoadingUserData,
    mutate: mutationUserData,
    reset: resetStateUserData,
  } = useMutation(getUserData);
  const myToko = useSelector((state: RootState) => state.MyToko);
  const userData = useSelector((state: RootState) => state.UserData && state.UserData.user);
  const goToPageHook = (url: string) => {
    router.push({
      pathname: url,
    });
  };
  const getInitialUserData = () => {
    if (Object.keys(userData || {}).length > 0) {
      // if (router.pathname === '/admin') {
      //   handleGetUser();
      // }
      return;
    }
    handleGetUser();
  };

  const handleGetUser = () => {
    mutationUserData(undefined, {
      onSuccess: (response) => {
        dispatch(saveUserData(response.data));
      },
      onError: () => {
        resetStateUserData();
      },
    });
  };

  const goToPageQuery = (url: string, query: string) => {
    router.push({
      pathname: url,
      query: query,
    });
  };

  const goToPageSearch = (url: string, search?: string) => {
    router.push({
      pathname: url,
      search: search,
    });
  };

  const goBack = () => {
    console.log('roue', router);
    if (router.pathname === '/product/search') {
      router.push('/');
    } else {
      router.back();
    }
  };

  const currentPage = (): string => {
    return router?.pathname || '';
  };

  const getParams = (): ParsedUrlQuery => {
    return router?.query || undefined;
  };

  const isReady = () => {
    return router?.isReady || false;
  };

  const getMyTokoApi = () => {
    mutationMyToko(undefined, {
      onSuccess: (response) => {
        dispatch(
          saveTokoData({
            toko: response.data,
          }),
        );
      },
    });
  };

  const onGetMyToko = () => {
    if (!myToko) {
      return getMyTokoApi();
    }
    if (router.pathname === '/admin') {
      return getMyTokoApi();
    }
  };

  return {
    onGetMyToko,
    goToPageHook,
    goToPageQuery,
    goBack,
    currentPage,
    getParams,
    isReady,
    getInitialUserData,
    handleGetUser,
    isLoadingUserData,
    goToPageSearch,
  };
};

export default useLayout;
